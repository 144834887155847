<template>
  <div class="tab-bc-box">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">练习与考试</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tab-box" v-if="loading">
      <el-tabs class="tab-toggle">
        <el-tab-pane>
          <span slot="label">课后练习</span>
          <ul class="after-pane">
            <li v-for="video in videoPage" :key="video.id">
              <b>{{ video.video_num }}</b>
              <span
                >{{ video.title }}（共{{
                  video.question_total ? video.question_total : 0
                }}题）</span
              >
              <button @click="toPractice(video.id, video.title)">
                <i class="el-icon-edit-outline"></i>
                开始练习
              </button>
            </li>
            <el-pagination
              layout="prev,pager,next"
              :total="totalNum"
              :page-size="pageSize"
              background
              prev-text="上一页"
              next-text="下一页"
              @current-change="handleCurrentChange"
              v-if="videoPage.length !== 0"
            >
            </el-pagination>
          </ul>
          <el-empty
            v-if="empty"
            :image="require(`../assets/images/no-data.png`)"
            :description="`暂无${$route.query.tid ? '课程' : '试卷'}`"
          ></el-empty>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">课后考试</div>
          <ul class="after-pane">
            <li>
              <!-- <b>第二节</b> -->
              <span
                >{{ coursePage.title }}（共{{
                  coursePage.question_total ? coursePage.question_total : 0
                }}题）</span
              >
              <button @click="judgeJumpExam(coursePage.title)">
                <i class="el-icon-edit-outline"></i> 开始考试
              </button>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  data() {
    return {
      coursePage: {},
      videoPage: [],
      totalNum: 1,
      pageSize: 1,
      currentPage: 1,
      empty: false,
      loading: false,
    };
  },

  components: { Breadcrumb },

  computed: {},
  mounted() {
    setTimeout(() => {
      this.tr = false;
    }, 2000);
    this.getExamPage();
  },
  methods: {
    //判断能否进入试卷页
    judgeJumpExam(et) {
      let fd = new FormData();
      fd.append("course_id", this.$route.query.cid);
      this.axios.post("/exam/afterclass_exam", fd).then((res) => {
        if (res.data.code === 1 || res.data.code === 2) {
          this.$message.error(res.data.msg);
        } else {
          this.$router.push(
            `/exampage?examid=${this.$route.query.cid}&examtitle=${et}`
          );
        }
      });
    },
    getExamPage() {
      let fd = new FormData();
      fd.append("course_id", this.$route.query.cid);
      fd.append("page_size", 10);
      this.axios.post("/exam/exam_list", fd).then((res) => {
        this.coursePage = res.data.course_info;
        this.videoPage = res.data.video_info;
        this.totalNum = res.data.count;
        this.loading = true;
        if (this.totalNum == 0) {
          this.empty = true;
        }
        this.pageSize = Number(res.data.page_size);
      });
    },
    //更改页码
    handleCurrentChange(page) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("course_id", this.$route.query.cid);
      fd.append("page_size", 10);
      this.axios.post("/exam/exam_list", fd).then((res) => {
        this.coursePage = res.data.course_info;
        this.videoPage = res.data.video_info;
        this.totalNum = res.data.count;
        this.pageSize = res.data.page_size;
      });
    },
    toPractice(id, pt) {
      let fd = new FormData();
      fd.append("video_id", id);
      this.axios.post("/exam/practice_exam", fd).then((res) => {
        if (res.data.code === 2 || res.data.code === 3) {
          this.$message.error(res.data.msg);
        } else {
          this.$router.push(`/exampage?vid=${id}&pt=${pt}`);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-bc-box {
  width: 100%;
  min-height: calc(100vh - 80px);
  background: #f8f8f8;
  margin: 0 auto;
  padding-top: 1px;
  padding-bottom: 40px;

  .tab-box {
    max-width: 1200px;
    border-radius: 4px;
    background: #ffffff;
    margin: 0 auto;
    // margin-bottom: 50px;
    // overflow: auto;

    .el-tabs {
      margin: 25px auto 0;
    }
    .tab-toggle {
      .after-pane {
        max-width: 800px;
        margin: 20px auto 78px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 44px 30px 44px 26px;
        box-sizing: border-box;
        li {
          list-style-type: none;
          max-width: 746px;
          height: 62px;
          border-bottom: 1px solid #ededed;
          padding: 20px 30px 20px 35px;
          box-sizing: border-box;
          font-size: 14px;
          color: #333;
          cursor: default;
          b {
            font-weight: normal;
            margin-right: 20px;
          }
          button {
            float: right;
            width: 95px;
            height: 31px;
            background: #2badef;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            border: 0;
            cursor: pointer;
            &:active {
              background: rgba($color: #2badef, $alpha: 0.6);
            }
          }
        }
        .el-pagination {
          text-align: center;
          margin-top: 36px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tab-toggle {
  .el-tabs__header {
    width: 20%;
    margin: 20px auto;
    .el-tabs__item {
      font-size: 18px;
      &:hover {
        color: #2badef;
      }
    }
    .el-tabs__item.is-active {
      color: #2badef;
    }
  }
  .el-tabs__nav-wrap::after {
    content: none;
  }
  .el-tabs__active-bar {
    background-color: #2badef;
  }
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
